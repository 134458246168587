import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import { renderLinks } from '../../services/links'
import './styles.scss'

const links = [
  { label: 'Terms of Service', url: '/terms-of-service'  },
  { label: 'Privacy Policy',  url:'/privacy-policy'  }
]

const Footer = () => {
  const { contentfulFooterContent: footerData } = useStaticQuery(graphql`
    query FooterProductPagesQuery {
      contentfulFooterContent {
        company {
          label
          url
        }
        logo {
          file {
            contentType
            fileName
            url
          }
        }
        products {
          label
          url
        }
        partners {
          label
          url
        }
        resources {
          label
          url
        }
        description
      }
    }
  `)

  const productLinks = renderLinks(footerData.products, 'Products', { className: 'link' })
  const partnerLinks = renderLinks(footerData.partners, 'Partners', { className: 'link' })
  const aboutLinks = renderLinks(footerData.company, 'Company', { className: 'link' })
  const resourcesLinks = renderLinks(footerData.resources, 'Resources', { className: 'link' })

  return (
    <div className='eg-footer'>
      <div className='upper-part'>
        <div className='detail'>
          <div className='logo'>
            <img src={ footerData.logo.file.url } alt='zsf-logo' />
          </div>
          <div className='info'>
            { footerData.description }
          </div>
        </div>
        <div className='section'>
          <div className='title'>
            Product
          </div>
          { productLinks.map(item => item()) }
        </div>
        <div className='section'>
          <div className='title'>
            Partner
          </div>
          { partnerLinks.map(item => item()) }
        </div>
        <div className='section'>
          <div className='title'>
            About
          </div>
          { aboutLinks.map(item => item()) }
        </div>
        <div className='section'>
          <div className='title'>
            Resources
          </div>
          { resourcesLinks.map(item => item()) }
        </div>
      </div>
      <div className='inner'>
        <div className='left'>
          © ZeroStoreFront { new Date().getFullYear() }
        </div>
        <div className='right'>
          <div className='links'>
            {links.map((link, index) => (
              <a key={index} href={link.url} className='link'>{link.label}</a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  data: PropTypes.object
}

export default Footer
